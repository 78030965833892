// SPDX-FileCopyrightText: Copyright 2022 Tilila Solutions Limited
// SPDX-License-Identifier: LicenseRef-TililaSolutionsWorkForHire
// SPDX-FileType: SOURCE

import { Controller } from "@hotwired/stimulus"
import { DataTable } from "simple-datatables";
import { Loader } from "@googlemaps/js-api-loader"

// Connects to data-controller="deliveries"
export default class extends Controller {
  static targets = ['table', 'status', 'updatedAt', 'map', "pickupAddress", "dropoffAddress", "driverLocation"];

  connect() {
    if (this.hasTableTarget) {
      this.initDataTable();
    }
    if (this.hasMapTarget){
      const loader = new Loader({
        apiKey: this.mapTarget.dataset.apikeyValue,
        version: "weekly",
        libraries: ['geometry', 'places']
      });
      loader.load().then(() => this.initMap());
    }
  }

  initDataTable() {
    this.dataTable = new DataTable(this.tableTarget);
  }

  initMap() {
    // Get pickup and drop-off coordinates from data attributes
    const pickupLat = parseFloat(this.pickupAddressTarget.dataset.pickuplatValue);
    const pickupLng = parseFloat(this.pickupAddressTarget.dataset.pickuplngValue);
    const dropoffLat = parseFloat(this.dropoffAddressTarget.dataset.dropofflatValue);
    const dropoffLng = parseFloat(this.dropoffAddressTarget.dataset.dropofflngValue);

    const pickupLocation = new google.maps.LatLng(pickupLat, pickupLng);
    const dropoffLocation = new google.maps.LatLng(dropoffLat, dropoffLng);
    this.pickupLocation = pickupLocation;
    this.dropoffLocation = dropoffLocation;
    // Calculate the center and zoom level
    const center = this.calculateCenter(pickupLocation, dropoffLocation);
    const zoom = this.calculateZoom(pickupLocation, dropoffLocation);

    const mapOptions = {
      zoom,
      center,
      mapTypeId: "roadmap",
    };

    this.map = new google.maps.Map(this.mapTarget, mapOptions);

    // Create markers for pickup and drop-off locations
    this.pickupMarker = this.createMarker(pickupLocation, "Pickup", "green");
    this.dropoffMarker = this.createMarker(dropoffLocation, "Drop-off", "red");
    
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(pickupLocation);
    bounds.extend(dropoffLocation);

    this.map.fitBounds(bounds);
    this.updateDriverLocation();
    // Fetch driver location and update the map at a 10 second interval
    this.driverLocationIntervalId = setInterval(() => this.updateDriverLocation(), 10000);
  }

  calculateCenter(pickupLocation, dropoffLocation) {
    return google.maps.geometry.spherical.interpolate(pickupLocation, dropoffLocation, 0.5);
  }

  calculateZoom(pickupLocation, dropoffLocation) {
    const distance = google.maps.geometry.spherical.computeDistanceBetween(pickupLocation, dropoffLocation);
    const baseZoom = 14;

    if (distance < 1000) {
      return baseZoom;
    } else if (distance < 5000) {
      return baseZoom - 1;
    } else {
      return baseZoom - 2;
    }
  }

  createMarker(location, title, color) {
    return new google.maps.Marker({
      position: location,
      map: this.map,
      title,
      icon: {
        url: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
      },
    });
  }

  async updateDriverLocation() {
    try {
      const updatedAtElement = this.updatedAtTarget
      console.log(updatedAtElement.dateTime);
      const response = await fetch(window.location.toString(), {
          credentials: 'same-origin',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Accept': 'application/json',
            'If-Modified-Since': updatedAtElement.dateTime
          },
        });

      if (response.status === 304) {
        // The resource has not been modified since the last time it was fetched
        return;
      } 

      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }

      const data = await response.json();
      // Update Non-Location Text Fields
      this.statusTargets.forEach(t => {t.innerText = `${data.status}`});
      const updatedTime = new Date(data.updated_at);
      const formattedUpdatedTime = updatedTime.toLocaleString();
      this.updatedAtTargets.forEach(t => { t.innerText = formattedUpdatedTime;
                                           t.dateTime = updatedTime.toISOString();});

      const currentGps = data.current_gps;
      if (!currentGps) { return; }
      
      // Extract latitude and longitude from the current_gps field
      const [_, lng, lat] = currentGps.match(/POINT \(([-\d.]+) ([-\d.]+)\)/);

      this.driverLocation = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));

      if (!this.driverMarker) {
        console.log("Creating Driver Marker");
        this.driverMarker = this.createMarker(this.driverLocation, "Driver", "blue");
      } else {
        this.driverMarker.setPosition(this.driverLocation);
      }

      const bounds = new google.maps.LatLngBounds();
      bounds.extend(this.pickupLocation);
      bounds.extend(this.dropoffLocation);
      bounds.extend(this.driverLocation);

      this.map.fitBounds(bounds);

      // Update Location
      this.driverLocationTargets.forEach(t => {t.innerText = `(${this.driverLocation.lat().toFixed(6)}, ${this.driverLocation.lng().toFixed(6)})`}
        );
    } catch (error) {
      console.error("Failed to update driver location:", error);
    }
  }

  stopRefreshing() {
    if (this.driverLocationIntervalId) {
      clearInterval(this.driverLocationIntervalId)
    }
  }

  disconnect() {
    this.stopRefreshing()
  }
}
