// SPDX-FileCopyrightText: Copyright 2022 Tilila Solutions Limited
// SPDX-License-Identifier: LicenseRef-TililaSolutionsWorkForHire
// SPDX-FileType: SOURCE

import { Controller } from "@hotwired/stimulus"
import {loadStripe} from '@stripe/stripe-js/pure'

// Connects to data-controller="stripe-setup-intent"
export default class extends Controller {
  static values = {
    clientSecret: String,
    publishableKey: String
  }

  async connect() {
    window.stripe = await loadStripe(this.publishableKeyValue);
    console.log("Hello, Stimulus!!!");
    const options = {
        clientSecret: this.clientSecretValue,
        // Fully customizable with appearance API.
        // appearance: {/*...*/},
      };

      // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
      window.elements = window.stripe.elements(options);

      // Create and mount the Payment Element
      var paymentElement = elements.create('payment');
      paymentElement.mount('#payment-element');
  }

  async submitToStripe(event){
  event.preventDefault();
  elements = window.elements;
  const {error} = await stripe.confirmSetup({
    //`Elements` instance that was used to create the Payment Element
    elements,
    confirmParams: {
      return_url: window.location.toString(),
    }
  });

  if (error) {
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Show error to your customer (for example, payment
    // details incomplete)
    const messageContainer = document.querySelector('#error-message');
    messageContainer.textContent = error.message;
  } else {
    // Your customer will be redirected to your `return_url`. For some payment
    // methods like iDEAL, your customer will be redirected to an intermediate
    // site first to authorize the payment, then redirected to the `return_url`.
  }
}
}
