import { Controller } from "@hotwired/stimulus"

import intlTelInput from 'intl-tel-input';

// Connects to data-controller="legal-entities"
export default class extends Controller {
  connect() {
    var input = window.document.querySelector("#legal_entity_primary_phone_e164")
    intlTelInput(input, {
      // any initialisation options go here
      initialCountry: 'us',
      onlyCountries: ['us'],
      hiddenInput: '[primary_phone_e164]',
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js"
    });
  }
}
