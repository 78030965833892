// SPDX-FileCopyrightText: Copyright 2022 Tilila Solutions Limited
// SPDX-License-Identifier: LicenseRef-TililaSolutionsWorkForHire
// SPDX-FileType: SOURCE

import { Controller } from "@hotwired/stimulus"
import { DataTable } from "simple-datatables";

// Connects to data-controller="delivery"
export default class extends Controller {
  connect() {
    const myTable = document.querySelector(".table");
    const dataTable = new DataTable(myTable);
  }
}
