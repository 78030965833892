// SPDX-FileCopyrightText: Copyright 2022 Tilila Solutions Limited
// SPDX-License-Identifier: LicenseRef-TililaSolutionsWorkForHire
// SPDX-FileType: SOURCE

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delivery-driver"
export default class extends Controller {
  connect() {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };

      function success(pos) {
        const crd = pos.coords;

        console.log('Your current position is:');
        console.log(`Latitude : ${crd.latitude}`);
        console.log(`Longitude: ${crd.longitude}`);
        console.log(`More or less ${crd.accuracy} meters.`);

        fetch(window.location.toString(), {
          method: 'PATCH',
          body: JSON.stringify({delivery: {
            latitude: crd.latitude,
            longitude: crd.longitude,
            location_accuracy:crd.accuracy
          }}),
          credentials: 'same-origin',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Accept': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
          },
        })
      }

      function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }

      if (navigator.geolocation) {
        this.refreshTimer = setInterval(function () {
          navigator.geolocation.getCurrentPosition(success, error, options);
        }, 5000);
      } else {
        alert("Please enable geolocation to proceed.")
      }
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  disconnect() {
    this.stopRefreshing()
  }
}
