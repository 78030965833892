// SPDX-FileCopyrightText: Copyright 2022 Tilila Solutions Limited
// SPDX-License-Identifier: LicenseRef-TililaSolutionsWorkForHire
// SPDX-FileType: SOURCE

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.textContent = "Hello World!"
  }
}
