import { Controller } from "@hotwired/stimulus"

import intlTelInput from 'intl-tel-input';
import { DataTable } from "simple-datatables";

// Connects to data-controller="users"
export default class extends Controller {
  connect() {
    const myTable = document.querySelector(".table");
    if (myTable) {
      const dataTable = new DataTable(myTable);      
    }
    var input = window.document.querySelector("#user_contact_number_e164")
    
    if (input) {
      input.setAttribute('name', 'old_contact_number_e164');
      intlTelInput(input, {
        // any initialisation options go here
        initialCountry: 'us',
        onlyCountries: ['us'],
        autoPlaceholder: 'aggressive',
        hiddenInput: 'user[contact_number_e164]',
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js"
      });
    }
  }
}
