// SPDX-FileCopyrightText: Copyright 2022 Tilila Solutions Limited
// SPDX-License-Identifier: LicenseRef-TililaSolutionsWorkForHire
// SPDX-FileType: SOURCE

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delivery-tracking"
export default class extends Controller {
    static values = {
      pickupLatitude: String,
      pickupLongitude: String,
      dropoffLatitude: String,
      dropoffLongitude: String,
      centerLatitude: String,
      centerLongitude: String,
      centerZoom: String,
      currentLatitude: String,
      currentLongitude: String
    }


  connect() {
    console.log("Hello, Stimulus!!!");

    function initMap() {
      const myLatLng = { lat: 1, lng: 1 };
      const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 4,
        center: myLatLng,
      });

      new google.maps.Marker({
        position: { lat: 1, lng: 1},
        map,
        title: "Pickup",
      });

      new google.maps.Marker({
        position: { lat: 1.1, lng: 1.1},
        map,
        title: "Dropoff",
      });
    }

    window.initMap = initMap;
  }
}
