import { Controller } from "@hotwired/stimulus"

import intlTelInput from 'intl-tel-input';

// Connects to data-controller="locations"
export default class extends Controller {
  static targets = ['address'];

  connect() {
    var contact_number_input = window.document.querySelector("#location_contact_number_e164")
    contact_number_input.setAttribute('name', 'old_contact_number_e164');
    intlTelInput(contact_number_input, {
      // any initialisation options go here
      initialCountry: 'us',
      onlyCountries: ['us'],
      autoPlaceholder: 'aggressive',
      hiddenInput: 'location[contact_number_e164]',
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js"
    });

    const apikeyValue = this.addressTarget.dataset.apikeyValue;
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key='+apikeyValue+'&libraries=places';
    script.onload = () => this.intializePlacesInput();
    document.head.appendChild(script);
  }

  intializePlacesInput() {
    var options = {
      types: ['address'],
      componentRestrictions: {country: 'us'}
    };

    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, options);
    this.autocomplete.addListener("place_changed",this.placeChanged.bind(this));
  }

  placeChanged() {
    const place = this.autocomplete.getPlace();
    console.log(place);
  }
}
